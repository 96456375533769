import * as browser from "./browser";
import {_wpnGetPageType, BUBBLE_BOTTOM_START, BUBBLE_HEIGHT_AND_SEPARATION} from "./product_detection";
import * as ui from "./ui";
import * as server from "./server";
import {_wpnGetCurrentExperiments} from "./experiments";
import {checkFlowyOverlappingBubbles} from "./ui";

//Event type constant for chat
const CHAT_EVENT = 9;

/**
 * Removes a single parameter from the URL
 * @param url
 * @param parameter
 * @returns {*}
 * @private
 */
function _wpnRemoveParametersFromUrl(url, parameter) {
  let rtn = url.split("?")[0],
    param,
    params_arr = [],
    queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === parameter) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

/**
 * Removes utm and gclid parametes from URL, as well as anchors
 * @param url
 * @returns {*}
 * @private
 */
export function _wpnRemoveUtmsFromUrl(url) {
  let toRemove = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'fbclid', 'gclid', 'SID'];
  for (let i = 0; i < toRemove.length; i++) {
    url = _wpnRemoveParametersFromUrl(url, toRemove[i]);
  }
  url = url.split('#')[0];

  let lastChar = url.charAt(url.length-1);
  if (lastChar === "?") {
    url = url.substring(0, url.length - 1);
  }

  return url;
}

export function displayChatWidget(chatData) {
  let body = document.getElementsByTagName('body')[0];
  let chatHtml = chatData.mobileHtml;
  if (browser._wpnGetDeviceType() === browser.DEVICE_DESKTOP) {
    chatHtml = chatData.html;
  }
  body.insertAdjacentHTML('beforeend', chatHtml);
  let chatBtns = document.getElementsByClassName('_wpn_chat_link');

  if (!window['wpnObject'].hasOwnProperty('bubbles')) {
    window['wpnObject'].bubbles = {left: [], right: [], center: []};
  }

  let container = document.getElementById('_wpn_chat_container');
  let position = container.dataset.position;

  if (chatBtns.length > 0) {
    for (let i = 0; i < chatBtns.length; i++) {
      (function() {
        let chatBtn = chatBtns[i];

        chatBtn.addEventListener('click', function (e) {
          server.trackEvent(window['wpnObject'].clientId, CHAT_EVENT, 'click', null, null, null, _wpnGetPageType(), this.dataset.link,
              _wpnRemoveUtmsFromUrl(document.referrer), _wpnGetCurrentExperiments(), null);
          let redirectWindow = window.open(this.dataset.link, '_blank');
          redirectWindow.location;
        });

        let originalColor = ui.rgbToHex(chatBtn.style.backgroundColor);
        chatBtn.addEventListener('mouseout', function () {
          chatBtn.style.backgroundColor = originalColor;
        });

        chatBtn.addEventListener('mouseover', function () {
          chatBtn.style.backgroundColor = ui.colorLuminance(originalColor, -0.2);
        });
      })()

      window['wpnObject'].bubbles[position].push(chatBtns[i].id);
    }
  }


  container.style.bottom = (BUBBLE_BOTTOM_START+Math.max(BUBBLE_HEIGHT_AND_SEPARATION*(window['wpnObject'].bubbles[position].length - chatBtns.length), 0)) + "px";
  if (chatBtns.length == 2 && position != "center") {
    const facebookMessenger = document.getElementById("_wpn_chat_fb");
    if (facebookMessenger !== null) {
      facebookMessenger.style.marginBottom = "10px";
    }
  }

  ui.checkFlowyOverlappingBubbles();
  server.trackEvent(window['wpnObject'].clientId, CHAT_EVENT, 'view', null, window.location.href, null, _wpnGetPageType(), null,
    _wpnRemoveUtmsFromUrl(document.referrer), _wpnGetCurrentExperiments(), null);
}